<template>
    <div class="cart">
        <div class="cart-header">
            <div class="cart-header-title">投放方案清单</div>
            <div class="cart-header-main">
                <el-row style="width: 100%;display: flex;align-items: center;">
                    <el-col :span="1">
                        <span type="primary" style="color:#409EFF;cursor:pointer;" @click="onSetAllSelectStatus">反选</span>
                    </el-col>
                    <el-col :span="1">类型</el-col>
                    <el-col :span="5">产品包/套餐</el-col>                
                    <el-col :span="2">形式</el-col>
                    <el-col :span="5">投放周期</el-col>
                    <el-col :span="2">投放频次(次/天)<br/>列车组数</el-col>
                    <el-col :span="2">版本时长(秒)</el-col>
                    <el-col :span="2">费用(万元)</el-col>
                    <el-col :span="3">加入时间</el-col>
                    <el-col :span="1">删除</el-col>
                </el-row>
            </div>
        </div>
        <div class="cart-content">
            <!-- 列表显示购物清单 -->
            <div class="cart-content-main" v-for="(item, index) in cartList" v-bind="index">
                <el-row style="width: 100%;">
                    <el-col :span="1">
                        <el-checkbox v-model="item.checked" @change="onChangeSelect(item)"></el-checkbox>
                    </el-col>
                    <el-col :span="1">
                        {{item.item.groupLevel==2?"--":item.item.typeId===1 || item.item.typeId==='1'?"LED":item.item.typeId===2 || item.item.typeId==='2'?"灯箱":"列车"}}
                    </el-col>
                    <el-col :span="5">
                        <!-- <img :src="productDictList[item.id].image">
                        <span>{{productDictList[item.id].name}}</span> -->
                        <span>{{item.item.name}}</span>
                    </el-col>
                
                <el-col :span="2">
                {{item.item.groupLevel==2?item.item.change?"产品包(调整)":"产品包":"套餐"}}
                </el-col>
                <el-col :span="5">
                    <span>{{item.item.sDate}}-{{item.item.eDate}}</span>
                </el-col>
                <el-col :span="2">
                    <span v-if="item.item.typeId===1 || item.item.typeId===4 || item.item.groupLevel===2">{{item.item.times}}</span><span v-else>--</span>
                </el-col>
                <el-col :span="2">
                    <span v-if="item.item.typeId===1 || item.item.typeId===4">{{item.item.length}}</span><span v-else>--</span>
                </el-col>
                <el-col :span="2">
                    ￥ {{item.item.cost}}
                </el-col>
                <el-col :span="3">
                    {{item.item.addTime}}
                </el-col>
                <!-- <div class="cart-count">
                    <span class="cart-control-minus"
                          @click="handleCount(index, -1)">-</span>
                    {{item.count}}
                    <span class="cart-control-add"
                          @click="handleCount(index, 1)">+</span>
                </div> -->
                <!-- <div class="cart-cost">
                    ￥ {{productDictList[item.id].cost * item.count}}
                </div> -->
                
                <el-col :span="1">
                    <span class="cart-control-delete"
                          @click="handleDelete(item)">移除</span>
                </el-col>
                </el-row>
            </div>
            <div class="cart-empty" v-if="!cartList.length">购物车为空</div>
        </div>

        <div class="cart-footer" v-show="cartList.length">
            <div style="display: flex;padding: 0 16px;justify-content: space-between;">
                <div>
                    <el-button v-if="countAll>0"  type="danger"  @click="handleMoveSelect">移除选中项</el-button>
                    <div class="cart-footer-desc">
                        购物车共<span>{{cartList.length}}</span>条，选中<span>{{countAll}}</span>条，选中项合计 <span>{{costAll}}</span>万元
                    </div>
                </div>
                <div>
                    <div v-if="countAll>0" style="margin-left:20px;" class="cart-control-order" @click="handleOrder">智能运算</div>
                    <el-button style="margin-left:20px;" type="danger" @click="onEmptyCart">清空购物车</el-button>
                </div>
            </div>
        </div>
        <el-dialog title="运算结果" v-model="viewMediaVisible" width="60%" @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    v-if='viewMediaVisible'
                                    append-to-body>
                                    <!-- :data="CalculateMultiSmart"  -->
            <effect-result  @close="viewMediaVisible=false" 
                :discount-price='costAll'                 
                :cart-list="toResult"
                :show-save="1"
                @changeDiscount="changeDiscount"/>
        </el-dialog>

    </div>
</template>

<script>
import EffectResult from '@/views/Business/Smart/EffectResult.vue';
import { mapGetters } from "vuex";


    export default {
        name: "cart",
        components:{EffectResult},
        data(){
            return {
                promotion: 0,
                promotionCode: '',
                // productList: product_data
                viewMediaVisible:false,
                // CalculateMultiSmart:{CPM:28,Frequency:2.5,chudarenshu:13542,Reach:247512}
                toResult:[],
                totalCost:0,
            }
        },
        computed: {
            //购物车数据
            cartList(){
                console.log('aaa',this.$store.state.cart.cartList)
                // console.log('aa',JSON.stringify(this.$store.state.cart.cartList))
                return this.$store.state.cart.cartList;
            },
            //设置字典对象，方便查询
            productDictList(){
                const dict = {};
                this.productList.forEach(item => {
                    dict[item.id] = item;
                });
                return dict;
            },
            // //购物车商品金额总数
            costAll(){
                let cost = 0;
                this.cartList.forEach(item => {
                    if (item.checked)   {
                        cost =cost + Number(item.item.cost);
                    }                     
                    // console.log('cost',cost,item.item.cost)
                });
                cost = parseFloat(cost).toFixed(2);
                console.log('cost',cost)
                return cost;
            },
            // //购物车商品选中数量
            countAll(){
                let cnt = 0;
                this.cartList.forEach(item => {
                    if (item.checked)   {
                        cnt ++;
                    }                     
                    // console.log('cost',cost,item.item.cost)
                });
                return cnt;
            },
            // //购物车商品总价
            // costAll(){
            //     let cost = 0;
            //     this.cartList.forEach(item => {
            //         cost += this.productDictList[item.id].cost * item.count;
            //     });
            //     return cost;
            // },
            // ...mapGetters(['CalculateMultiSmart'])
        },
        mounted(){
            
        },

        methods: {
            async handleMoveSelect(){
                this.$confirm('此操作将移除购物车所选资源, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                }).then(async () => {
                    this.cartList.forEach(ele => {
                        if (ele.checked){
                            this.$store.commit('cart/deleteCart', ele);
                        }                    
                    });
                    this.$message({
                            type: 'success',
                            message: '移除成功!'
                    });
                        
                })
            },

            //通知Vuex,完成下单
            async handleOrder(){
                // this.$store.dispatch('buy').then(() => {
                //     window.alert('购买成功');
                // })
                // console.log('cartList2',this.cartList);
                this.toResult=[];
                this.cartList.forEach(ele => {
                    if (ele.checked){
                        this.toResult.push(ele);
                    }                    
                });
                // this.toResult = this.cartList;
                
                // var Ip=returnCitySN['cip']
                // var cityname=returnCitySN['cname']
                // localStorage.setItem('Ip', Ip)
                // localStorage.setItem('cityName', cityname)

                //设置购物车的资源套餐中是否包含灯箱或者Led
                // this.cartList.forEach(ele => {
                //     if (ele.groupLevel==1 && ele.isFree==false){
                //         if (ele.typeId=='1'){
                //             this.cartHaveLed=true;
                //         }
                //         if (ele.typeId=='2' || ele.typeId=='3'){
                //             this.cartHaveDx=true;
                //         }
                //     }
                //     ele.detailId=-1;
                // });
                // this.cartHaveLed=true;

                
                this.viewMediaVisible=true;
            },

            onSetAllSelectStatus(){
                this.$store.commit('cart/setCartAllSelectStatus', );
            },

            onChangeSelect(item){
                // console.log('item',item)
                this.$store.commit('cart/setCartOneSelectStatus', item);
            },
            
            onEmptyCart(){
                this.$confirm('此操作将清空购物车所有资源, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                }).then(async () => {
                        this.$store.commit('cart/emptyCart', );
                        this.$message({
                                type: 'success',
                                message: '清除成功!'
                        });
                        
                })
            },

            changeDiscount(){

            },

            //根据index查找商品id进行删除
            handleDelete(item){
                // console.log('item',item)
                this.$confirm('此操作将移除'+item.item.name+', 是否继续?', '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
						}).then(async () => {
								this.$store.commit('cart/deleteCart', item);
                                
								this.$message({
										type: 'success',
										message: '移除成功!'
								});
								
						})

                
            },
            open(){
                // console.log('do open')
                this.$nextTick(() => {
                //  执行echarts方法
                
                })
            },
        }
    }
</script>

<style scoped>
    .cart{
        margin: 32px;
        background: #fff;
        border: 1px solid #dddee1;
        border-radius: 10px;
    }
    .cart-header-title{
        padding: 16px 32px;
        border-bottom: 1px solid #dddee1;
        border-radius: 10px 10px 0 0;
        background: #f8f8f9;
    }
    .cart-header-main{
        padding: 8px 32px;
        overflow: hidden;
        border-bottom: 1px solid #dddee1;
        background: #eee;
        overflow: hidden;
    }
    .cart-empty{
        text-align: center;
        padding: 32px;
    }
    .cart-header-main div{
        text-align: center;
        float: left;
        font-size: 14px;
    }
    div.cart-info{
        width: 60%;
        text-align: left;
    }
    .cart-price{
        width: 10%;
    }
    .cart-count{
        width: 10%;
    }
    .cart-cost{
        width: 10%;
    }
    .cart-delete {
        width: 10%;
    }
    .cart-content-main{
        padding: 0 32px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        border-bottom: 1px dashed #e9eaec;
        overflow: hidden;
    }
    .cart-content-main div{
        float: left;
    }
    .cart-content-main img{
        width: 40px;
        height: 40px;
        position: relative;
        top: 10px;
    }
    .cart-control-minus,
    .cart-control-add{
        display: inline-block;
        margin: 0 4px;
        width: 24px;
        height: 24px;
        line-height: 22px;
        text-align: center;
        background: #f8f8f9;
        border-radius: 50%;
        box-shadow: 0 1px 1px rgba(0,0,0,.2);
        cursor: pointer;
    }
    .cart-control-delete{
        cursor: pointer;
        color: #2d8cf0;
    }
    .cart-promotion{
        padding: 16px 32px;
    }
    .cart-control-promotion,
    .cart-control-order{
        display: inline-block;
        padding: 8px 32px;
        border-radius: 6px;
        background: #2d8cf0;
        color: #fff;
        cursor: pointer;
    }
    .cart-control-move{
        display: inline-block;
        padding: 8px 32px;
        border-radius: 6px;
        background: red;
        color: #fff;
        cursor: pointer;
    }
    .cart-control-promotion{
        padding: 2px 6px;
        font-size: 12px;
        border-radius: 3px;
    }
    .cart-footer{
        padding: 32px;
        text-align: right;
    }
    .cart-footer-desc{
        display: inline-block;
        padding: 0 16px;
    }
    .cart-footer-desc span{
        color: #f2352e;
        font-size: 20px;
    }
</style>