<template>
    <div>        
        <!-- <div class="container"> -->
            <!-- <div  width="100%"> -->
                 <!-- <el-dialog title="" v-model="resultDialogVisible" width="60%" @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    append-to-body> -->
                        <div >
                            <div style="display:flex;justify-content:space-between">
                                <div class="crumbs">
                                    <el-breadcrumb separator="/">
                                        <el-breadcrumb-item>
                                            <div style="display:flex; margin-top:10px;">
                                                <i class="el-icon-coin grid-num"></i> 
                                                <div class="grid-num">投放总费用<span style="color:red;margin-left:20px;font-weight: bold;">{{localPrice}}</span>
                                                <span style="font-size: 12px;margin-left:10px;">万元</span></div>
                                            </div>
                                        </el-breadcrumb-item>
                                    
                                    </el-breadcrumb>
                                </div>
                                <div v-if="showSave==1" style="margin-right: 40px;margin-top: 5px;">
                                    <el-button style="float: right; padding: 3px 0;margin-top:-40px;" type="text" @click="setSaveDialogVisible=true">保存方案</el-button>
                                    <!-- <el-tooltip content="保存方案"  effect="light">
                                        <i class="el-icon-s-order"  style="color:blue;margin-left:15px;" @click="setSaveDialogVisible=true"></i>
                                    </el-tooltip> -->
                                    <!-- <el-tooltip content="导出Excel"  effect="light">
                                        <i class="el-icon-s-promotion"  style="color:blue;margin-left:15px;" @click="exportData(1)"></i>
                                    </el-tooltip>
                                    <el-tooltip content="导出PPT"  effect="light">
                                        <i class="el-icon-star-on"  style="color:blue;margin-left:15px;" @click="exportData(2)"></i>
                                    </el-tooltip> -->
                                    <!-- <el-button type="primary" @click="saveProject">保存方案</el-button> -->
                                </div>
                            </div>
                            <div>
                                <el-row :gutter="40" class="mgb20">                                    
                                    <el-col :span="19" style="align-self: center;">
                                        <el-row style="display:flex;justify-content: center;">
                                            <el-col :span="6">
                                             <el-card shadow="hover" :body-style="{ padding: '0px' }" style="width: 100%;" >                                                
                                                    <div class="grid-content grid-con-4" style="height:160px;">                                                
                                                        <div class="grid-cont-right" style="display:flex;border:0px;align-items: center;">
                                                            <div style="width:100%;display: flex;justify-content: center;">
                                                                <div><i class="el-icon-money grid-con-icon"></i></div>
                                                                <el-tooltip class="item" effect="dark" placement="right" >
                                                                    <template v-slot:content> 
                                                                        <div >
                                                                            <ul>
                                                                            　<li>即Cost per mill, 是指某一个营销活动，平均获得一千人次的接触度所需要花费的成本，以货币单位表示.</li>
                                                                            　<li>该指标可用于评估广告投放的经济性，评估不同市场的广告成本效益</li>
                                                                            </ul>
                                                                        </div>
                                                                    </template>
                                                                <div style="display:flex;flex-direction:column;justify-content: center;align-items:center;">
                                                                    <div class="grid-num">￥{{data.CPM}}元</div>
                                                                    <div>CPM</div>
                                                                </div>
                                                                </el-tooltip>
                                                            </div>
                                                        </div>
                                                    </div>
                                                 
                                            </el-card>
                                            </el-col>
                                        <!-- </el-row>
                                        <el-row style="margin-top:20px;width: 100%;"> -->
                                            <el-col :span="6">
                                                <el-card shadow="hover" :body-style="{ padding: '0px' }">
                                                    <div class="grid-content grid-con-3">
                                                        <i class="el-icon-s-goods grid-con-icon" ></i>
                                                        <el-tooltip class="item" effect="dark" placement="left-start" content="某个广告在一定时期内被观众接触的总人次，以千人次表示" >
                                                        
                                                        <div class="grid-cont-right" >                                                    
                                                            <div class="grid-num-small">{{data.Frequency}}</div>
                                                            <div>累计接触度(千人次)</div>
                                                        </div>
                                                        </el-tooltip>
                                                    </div>
                                                </el-card>
                                            </el-col>
                                            <el-col :span="6">
                                                <el-card shadow="hover" :body-style="{ padding: '0px' }">
                                                    <div class="grid-content grid-con-1">
                                                        <i class="el-icon-user-solid grid-con-icon"></i>
                                                        <el-tooltip class="item" effect="dark" placement="left-start" >
                                                            <template v-slot:content> 
                                                                <div >
                                                                    <ul>
                                                                        <li>到达率是一个时间上的纵向累积指标，它考察特定时间段内观众收看某一媒体或广告<br/>
                                                                        （或某一广告计划所能覆盖）的不重复的人数（或比例），反映了接触的受众规模。</li>
                                                                        <li>通俗解释到达率(千人)就是看到该广告的总人数</li>
                                                                    </ul>
                                                                </div>
                                                            </template>
                                                            <div class="grid-cont-right">
                                                                <div class="grid-num-small">{{data.chudarenshu}}</div>
                                                                <div>到达率(千人)</div>
                                                            </div>
                                                        </el-tooltip>
                                                    </div>
                                                </el-card>
                                            </el-col>
                                            <el-col :span="6">
                                                <el-card shadow="hover" :body-style="{ padding: '0px' }">
                                                    <div class="grid-content grid-con-2">
                                                        <i class="el-icon-message-solid grid-con-icon"></i>
                                                        <!-- content=" ，  " -->
                                                        <el-tooltip class="item" effect="dark" placement="left-start"  >
                                                            <template v-slot:content> 
                                                                <div >
                                                                    <ul>
                                                                    　<li>一个广告排期每条或总体被观众收看的次数</li>
                                                                    　<li>暴露频次用于广告投放计划传播深度的评估</li>
                                                                      <li>算法：累计接触度(千人次)除以到达率(千人)</li>
                                                                    </ul>
                                                                </div>
                                                            </template>
                                                        <div class="grid-cont-right">
                                                            <div class="grid-num-small">{{data.Reach}}</div>
                                                            <div>暴露频次(次)</div>
                                                        </div>
                                                        </el-tooltip>
                                                    </div>
                                                </el-card>
                                            </el-col>
                                        </el-row>
                                    </el-col>                         

                                    <el-col :span="5">                                      
                                        <div v-if="curTypeList[0]===true"> LED <span style="color:red;margin-left:2px;">{{curLedDiscount}}</span>折
                                            <!-- <el-slider style="margin-left:10px;width: 80%;" input-size="small" v-model="curLedDiscount" :min="2" :max="10" :step="0.1" @change="onSlideChange(1)" :format-tooltip="formatDiscountTooltip">
                                            </el-slider> -->
                                            <div>
                                            <el-input-number style="width: 135px;margin-top:5px;" v-model="curLedDiscount" :precision="1" :step="0.1" :min="1" :max="10" @change="onSlideChange(1)"></el-input-number>
                                            </div>
                                        </div>
                                        <div v-if="curTypeList[1]===true || curTypeList[2]===true" style="margin-top:10px;"> 灯箱<span style="color:red;margin-left:2px;">{{curDxDiscount}}</span>折
                                            <!-- <el-slider style="margin-left:10px;width: 80%;" input-size="small" v-model="curDxDiscount" :min="4" :max="10" :step="0.1" @change="onSlideChange(2)" :format-tooltip="formatDiscountTooltip">
                                            </el-slider> -->
                                            <div>
                                            <el-input-number style="width: 135px;margin-top:5px;" v-model="curDxDiscount" :precision="1" :step="0.1" :min="1" :max="10" @change="onSlideChange(2)"></el-input-number>
                                            </div>
                                        </div>     
                                        <div v-if="curTypeList[3]===true " style="margin-top:10px;"> 列车<span style="color:red;margin-left:2px;">{{curLcDiscount}}</span>折
                                            <div>
                                                <el-input-number style="width: 135px;margin-top:5px;" v-model="curLcDiscount" :precision="1" :step="0.1" :min="1" :max="10" @change="onSlideChange(3)"></el-input-number>
                                            </div>
                                        </div>     
                                       
                                    </el-col>
                                </el-row>

                            </div>                    
                        </div>
                <el-dialog title="保存方案" v-model="setSaveDialogVisible" width="40%" @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    append-to-body>
                    <set-save  @close="setSaveDialogVisible=false" 
                        :total-price="localPrice" 
                        :led-discount="curLedDiscount" 
                        :dx-discount="curDxDiscount" 
                        :lc-discount="curLcDiscount" 
                        :smart-result="data"
                        :cart-list="cartList" />
                </el-dialog>
            </div>
        <!-- </div> -->
    <!-- </div> -->
</template>

<script>
import { mapGetters } from "vuex";
import SmartResult from '@/views/Business/SmartResult.vue';
import {SaveProjectSmart,ProjectExport} from "@/api/operate"
import {getToken} from "@/utils/auth";
import SelectMedia from '@/views/Business/SelectMedia.vue';
// import SelectProduct from './SelectProduct.vue';
import SelectMediaPackage from '@/views/Business/SelectMediaPackage.vue';
import SetSave from './SetSave.vue';


export default {
    name: 'baseform',
    components:{SmartResult, SelectMedia,SelectMediaPackage,SetSave},
    props:['discountPrice','cartList','showSave'],
    computed: {...mapGetters(['categoryList','GetPlanPrice_ByCost','CalculateMultiSmart'])},
    data() {
        return {
           localPrice:-1,
           curTypeList:[],
           curLedDiscount:10,
           curDxDiscount:10,
           curLcDiscount:10,
           kanliList:[],
           setSaveDialogVisible:false,
           data:{},
        //    allProdPrice:0,
        };
    },
    
    async mounted() {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = 'http://pv.sohu.com/cityjson?ie=utf-8&ver='+Math.random();
        document.body.appendChild(s);
        // console.log('aaaaaa',this.cartList)
        this.init();
    },
    watch: {
        discountPrice: function () {
            // this.localPrice = this.discountPrice
            this.init();
        }
    },


    methods: {
        async init(){
            // this.getlocalPrice();
            this.localPrice = this.discountPrice;
            await this.checkMediaType();
            await this.createKanliList();
            this.onCalculate();    
        },

        getlocalPrice(){
            this.localPrice = 0;
            this.cartList.forEach(element => {
                this.localPrice = this.localPrice + element.item.cost;
            });
        },

        //计算总价格
        async onSlideChange(type){            
            var tPrice=0;
            // console.log('this.cartList',this.cartList)   
            // console.log('this.kanliList',this.kanliList)    
            
            Promise.all(this.kanliList.map((ele)=>{
                return new Promise(async (resolve, reject)=>{
                    const {sDate,eDate,times,ledKanli,dxKanli,digitalKanli,lcKanli}=ele;                    
                    // console.log('ele',ele)     
                    if (sDate!==""){    
                        if (ledKanli>0){
                            // console.log('do1')
                            await this.$store.dispatch('operate/GetPlanPrice_ByCost', {sDate,eDate,times,disCount:this.curLedDiscount,cost:ledKanli}).then(()=>{
                                tPrice=parseFloat(tPrice)+parseFloat(this.GetPlanPrice_ByCost[0].Price);
                            })                        
                        }
                        if (dxKanli>0){
                            await this.$store.dispatch('operate/GetPlanPrice_ByCost', {sDate,eDate,times,disCount:this.curDxDiscount,cost:dxKanli}).then(()=>{
                                tPrice=parseFloat(tPrice)+parseFloat(this.GetPlanPrice_ByCost[0].Price);
                            })
                        }
                        if (digitalKanli>0){
                            await this.$store.dispatch('operate/GetPlanPrice_ByCost', {sDate,eDate,times,disCount:this.curDxDiscount,cost:digitalKanli}).then(()=>{
                                tPrice=parseFloat(tPrice)+parseFloat(this.GetPlanPrice_ByCost[0].Price);
                            })
                        }
                        if (lcKanli>0){
                            console.log('jinlulieche')
                            await this.$store.dispatch('operate/GetPlanPrice_ByCost', {sDate,eDate,times:100,disCount:this.curLcDiscount,cost:lcKanli}).then(()=>{
                                tPrice=parseFloat(tPrice)+parseFloat(this.GetPlanPrice_ByCost[0].Price);
                            })
                        }
                    }else{
                        tPrice = parseFloat(tPrice)+parseFloat(ledKanli);
                    }
                    
                    resolve();
                })
                })).then((data)=>{
                        // this.getTotalPrice();
                    this.localPrice=parseFloat(tPrice).toFixed(2);
                    // console.log('pp',this.localPrice)
                    this.onCalculate();   
                });

        },


        createKanliList(){
            // console.log('cartList',this.cartList)    
    
            this.cartList.forEach(eleP => {
                // console.log('eleP',eleP)
                var eleS=eleP.item;
                // eleP.item.forEach(eleS => {
                    var ledCost=0;
                    var dxCost=0;
                    var digitalDxCost=0;
                    var lcCost=0;
                    // console.log('eleS',eleS)
                    if (eleS.groupLevel===1){
                                         
                        if (eleS.cost>0){
                            if (eleS.haveLed==='True' || eleS.haveLed===true){   
                                console.log('led')             
                                ledCost=eleS.kanli;
                            }
                            if (eleS.haveDx==='True' || eleS.haveDx===true){
                                // console.log('dengxiang')                              
                                dxCost=eleS.kanli;
                            }
                            if (eleS.haveDigitalDx==='True' || eleS.haveDigitalDx===true){                           
                                digitalDxCost=eleS.kanli;
                            }
                            if (eleS.haveLc==='True' || eleS.haveLc===true){
                                // console.log('dengxiang')                              
                                lcCost=eleS.kanli;
                            }
                            var obj={};
                            obj.sDate=eleS.sDate;
                            obj.eDate=eleS.eDate;
                            obj.times=eleS.times;
                            obj.ledKanli=ledCost;
                            obj.dxKanli=dxCost;
                            obj.digitalKanli=digitalDxCost;
                            obj.lcKanli=lcCost;
                            // console.log('1ji',obj,eleS)
                            this.kanliList.push(obj);
                        }
                    }else{
                        ledCost=0;
                        dxCost=0;
                        digitalDxCost=0;
                        lcCost=0;
                        if (eleS.change==false){        //未变化的产品包
                            var obj={};
                            obj.sDate="";
                            obj.eDate="";
                            obj.times="";
                            obj.ledKanli=eleS.cost;
                            obj.dxKanli=0;
                            obj.digitalKanli=0;
                            obj.lcKanli=0;
                            // console.log('1ji',obj)
                            this.kanliList.push(obj);
                        }else{
                            
                            eleS.pList.forEach(eleSub => {                             
                                if (eleSub.isFree===false){
                                    // console.log('jinru',eleSub,eleSub.typeId,eleSub.kanli)
                                    if (eleSub.typeId*1===1){
                                      
                                        ledCost=parseFloat(ledCost)+parseFloat(eleSub.kanli);
                                    }
                                    if (eleSub.typeId*1===2){
                                      
                                        dxCost=parseFloat(dxCost)+parseFloat(eleSub.kanli);
                                    }
                                    if (eleSub.typeId*1===3){
                                      
                                        digitalDxCost=parseFloat(digitalDxCost)+parseFloat(eleSub.kanli);
                                    }     
                                    if (eleSub.typeId*1===4){
                                      
                                      lcCost=parseFloat(lcCost)+parseFloat(eleSub.kanli);
                                  }                                                         
                                }                            
                            });
                            if (ledCost+dxCost+digitalDxCost+lcCost>0){
                                var obj={};
                                obj.sDate=eleS.sDate;
                                obj.eDate=eleS.eDate;
                                obj.times=eleS.times;
                                obj.ledKanli=ledCost;
                                obj.dxKanli=dxCost;
                                obj.digitalKanli=digitalDxCost;   
                                obj.lcKanli = lcCost; 
                                // console.log('2ji',obj)                        
                                this.kanliList.push(obj);
                            }
                        }                      

                    }
                // });
            });
            console.log('kanli',this.kanliList)
        },

        async onCalculate(){
            var Ip=localStorage.getItem('Ip');//returnCitySN['cip']
            var cityname=localStorage.getItem('cityName');//returnCitySN['cname']
            // localStorage.setItem('Ip', Ip)
            // localStorage.setItem('cityName', cityname)

            const loading = this.$loading({
                lock: true,
                text: '智能计算中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

                var newJson=[];
                this.cartList.forEach(element => {
                    newJson.push(element.item);
                });
                var condi ;
                condi = JSON.stringify(newJson);

                // console.log('condi',condi)
                var ip = localStorage.getItem('Ip');
                var cityName = localStorage.getItem('cityName');
                
                await this.$store.dispatch('operate/CalculateMultiSmart',{ip,cityName,budget:this.localPrice,ledDiscount:this.curLedDiscount,dxDiscount:this.curDxDiscount,lcDiscount:this.curLcDiscount,condi}).then(res=>{
                    console.log('CalculateMultiSmart',this.CalculateMultiSmart)
                    this.data=this.CalculateMultiSmart;
                    loading.close();
                    this.viewMediaVisible=true;
                });
        },

        checkMediaType(){
            
                var list=this.cartList;
                // console.log('zuozhege',list)
                var haveLed=false;
                var haveDx=false;
                var haveDigitalDx=false;
                var haveLc=false;
                // console.log('list',list)
                // console.log('111',list)
                list.forEach(ele => {
                    if (ele.item.groupLevel===1){
                        if (!ele.item.isFree || ele.item.isFree===false){
                            if (Number(ele.item.typeId)===1){
                                haveLed=true;
                            }
                            if (Number(ele.item.typeId)===2){
                                haveDx=true;
                            }
                            if (Number(ele.item.typeId)===3){
                                haveDigitalDx=true;
                            }
                            if (Number(ele.item.typeId)===4){
                                haveLc=true;
                            }
                        }
                    }else{
                        if (ele.item.change===true){
                            ele.item.pList.forEach(plist => {
                                if (plist.isFree===false){
                                    if (Number(plist.typeId)===1){
                                        haveLed=true;
                                    }
                                    if (Number(plist.typeId)===2){
                                        haveDx=true;
                                    }
                                    if (Number(plist.typeId)===3){
                                        haveDigitalDx=true;
                                    }
                                    if (Number(plist.typeId)===4){
                                        haveLc=true;
                                    }
                                }
                            });
                        }
                    }
                });
                this.curTypeList.push(haveLed);
                this.curTypeList.push(haveDx);
                this.curTypeList.push(haveDigitalDx);
                this.curTypeList.push(haveLc);
                // console.log('222',this.curTypeList)
                
        },
        
        formatDiscountTooltip(val) {
            return val+'折';
        },

        formatFreqTooltip(val) {
            return '每日'+val+'次';
        },



        open(){
            // console.log('do open')
            this.$nextTick(() => {
            //  执行echarts方法
            
            })
        },

    }
};
</script>

<style scoped>
.sub-title{
    font-size: large;
    margin-top:4px;
}
.item-pre{
    font-size: larger;
}
.button-footer{
    display: flex;
    flex-direction:row-reverse;
}
.product-name{
    font-size: xx-large;
    margin-top:-10px;
}
.card-group{
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
}
.group-item{
    padding: 20px;
    margin-left: 10px;
    
}
.box-card {
    width: 480px;
    
  }
   .text {
    font-size: 14px;
  }

  .item {
    /* margin-bottom: 18px; */
    margin-top:18px;
  }

  /* .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  } */
 

.transition-box {
    margin-bottom: 10px;
    width: 200px;
    height: 100px;
    border-radius: 4px;
    background-color: #409EFF;
    text-align: center;
    color: #fff;
    padding: 40px 20px;
    box-sizing: border-box;
    margin-right: 20px;
  }

.schart-box {
    display: inline-block;
    margin: 20px;
}
.schart {
    width: 600px;
    height: 400px;
}
.content-title {
    clear: both;
    font-weight: 400;
    line-height: 50px;
    margin: 10px 0;
    font-size: 22px;
    color: #1f2f3d;
}


.grid-content {
    display: flex;
    align-items: center;
    flex-direction:column;
    /* height: 120px; */
}

.grid-subcontent {
    display: flex;
    align-items: center;
    height: 50px;
}

.grid-cont-right {
    flex: 1;
    text-align: center;
    font-size: 14px;
    color: #999;
}

.grid-num {
    font-size: 15px;
    /* font-weight: bold; */
}

.grid-num-small {
    font-size: 16px;
    font-weight: bold;
}

.grid-subnum {
    font-size: 20px;
    font-weight: bold;
}

.grid-con-icon {
    font-size: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    color: #fff;
    border-radius: 50%;
    /* margin-left: 80px; */
}
.grid-subcon-icon{
    font-size: 30px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    color: #fff;
}

.grid-con-0 .grid-subcon-icon {
    background: rgb(184, 223, 223);
}

.grid-con-0 .grid-num {
    color: rgb(184, 223, 223);
}

.grid-con-1 .grid-con-icon {
    background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
    background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
    background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
    color: rgb(242, 94, 67);
}

.grid-con-4 .grid-con-icon {
    background: rgb(132, 94, 67);
}

.grid-con-4 .grid-num {
    color: rgb(132, 94, 67);
}

.grid-con-5 .grid-con-icon {
    background: rgb(66, 94, 67);
}

.grid-con-5 .grid-num {
    color: rgb(66, 94, 67);
}

.grid-con-6 .grid-con-icon {
    background: rgb(66, 136, 67);
}

.grid-con-6 .grid-num {
    color: rgb(66, 136, 67);
}

.clearfix{
    margin-top: -5px;
}
.subtitle{
    font-weight: bolder;
    padding-right: 5px;
}


</style>